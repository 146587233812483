<script>
import AddressForm from '../components/Forms/Partials/AddressForm'
import NewCoursePartial from '../components/Forms/Partials/NewCourse'
import NewPaymentPartial from '../components/Forms/Partials/NewPayment'
import DialogFormWrapper from '../components/Forms/Partials/DialogFormWrapper'
import ChooseClientAddressPartial from '../components/Forms//Partials/ChooseClientAddress'
import { getDistanceBetweenTwoPoints } from '../utils'
import { NewOrder } from '../models'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    AddressForm,
    NewCoursePartial,
    DialogFormWrapper,
    NewPaymentPartial,
    ChooseClientAddressPartial
  },
  data: () => ({
    ...new NewOrder(), // order, course, payment
    showAddressForm: false,
    chosenAddress: undefined,
    locationDiscount: null,
    clientType: null,
    destinationCoordinates: null,
    foundMatchingAddresses: false,
  }),
  computed: {
    ...mapState({
      department: state => state.core.department,
      isProcessing: state => state.orders.isProcessing,
      addresses: state => state.addresses.items,
      client: state => state.client.entity
    }),
    straightLineDistance () {
      const baseCoordinates = { baseLat: this.department.lat, baseLng: this.department.lng }
      return this.destinationCoordinates ? getDistanceBetweenTwoPoints(baseCoordinates, this.destinationCoordinates) : 0
    },
    orderFromTemplateAddresses() {
      if (this.foundMatchingAddresses) {
        return this.addresses
      } else {
        const clientAddresses = this.client.addresses.map((address) => ({ ...address, isNotOrderTemplateMatchingAddress: true }))
        return [...this.addresses, ...clientAddresses]
      }
    }
  },
  watch: {
    'order.addressId' (id) {
      this.populateAddressData(id)
    },
  },
  methods: {
    ...mapActions({
      addNewAddress: 'addresses/addNewItem',
      editAddress: 'addresses/editItem',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar',
      getAddresses: 'addresses/getItems',
      getSingleClient: 'client/getSingleClient',
    }),
    changeAddressFormStatus (status, isUpdating) {
      this.showAddressForm = status
      this.chosenAddress = isUpdating && this.addresses.find(address => address.id === this.order.addressId)
    },
    fetchClientAddresses (setAddress = false) {
      const clientId = this.order.middlemanId ? this.order.middlemanId : this.order.clientId
      const payload = {
        params: { filters: { clientId, departmentIds: [this.department.id] } }
      }

      if (this.addressQuery) {
        const addressQueryWithoutApartmentNumber = this.addressQuery.replace(/\/\d+$/, '').replace(/\bul\.?/g, '').replace(',', '')
        // remove no. 1 - remove apartment number
        // remove no. 2 - remove 'ul.', 'ul' abbreviation
        // remove no. 3 - remove comma
        payload.params.filters.query = addressQueryWithoutApartmentNumber
      }

      return this.getAddresses(payload).then(() => {
        const addressId = this.addresses?.[0]?.id
        if (addressId) this.foundMatchingAddresses = true
        if (setAddress) this.order.addressId = addressId
      })
    },
    addAddress (address) {
      const params = {
        ...address,
        clientId: this.order.middlemanId || this.order.clientId,
      } // if middlemanId is set, new address should be saved for middleman
      this.addNewAddress({ params })
        .then((resp) => {
          this.getSingleClient(this.order.clientId)
          // in the case where client new address doesn't match with addressQuery used in fetchClientAddresses
          // we need to also fetch single client object to provide all client addresses
          this.fetchClientAddresses()
          this.order.addressId = resp.id
          this.showAddressForm = false
        })
    },
    updateAddress (address) {
      const params = {
        tableName: 'addresses',
        params: { ...address },
        id: this.order.addressId
      }
      this.editAddress(params)
        .then(async () => {
          await this.fetchClientAddresses()
          this.showAddressForm = false
        })
    },
    populateAddressData (id) {
      const address = this.addresses.find(address => address.id === id)
      this.destinationCoordinates = address ? { destLat: address.location.lat, destLng: address.location.lng } : null
      if (this.updatePayment) {
        this.payment.discountJustification = address ? address.discountJustification : null
        this.payment.discount = address ? address.discount / 100 : null // editable
        this.locationDiscount = address ? address.discount / 100 : null // not editable
      }
    },
  }
}
</script>
